import React from "react";

import Layout from "components/Layout";
import InfoPage from "components/InfoPage";

const NotFound = () => {
  return (
    <Layout
      seo={{
        title: "404 - Rodzinne Resorty",
      }}
    >
      <InfoPage title="Błąd 404" text="Ta strona nie istnieje" />
    </Layout>
  );
};

export default NotFound;
